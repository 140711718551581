<div class="card-wrapper">
	<form class="form-wrapper" [formGroup]="formGroup">
		<input class="login-item" type="text" placeholder="Indirizzo e-mail" formControlName="email">

		<div class="password-wrapper">
			<div class="password-input">
				<input class="login-item" [type]="showPassword ? 'text' : 'password'" placeholder="Password" formControlName="password">
				<button class="show-password" (click)="showPassword = !showPassword">
					<i class="pi pi-eye"></i>
				</button>
			</div>
			<a class="forgot-password base-text" [routerLink]="getForgotPasswordLink()">
				Password dimenticata?
			</a>
		</div>

		<div class="login-button-wrapper">
			<button class="login-item login-button" [ngClass]="{'disabled': !formGroup.valid}"
					(click)="loginUser(AuthType.INTERNAL)" [disabled]="!formGroup.valid">
				Sign in
			</button>
		</div>

		<span class="or base-text">Oppure</span>

		<div class="login-google-wrapper">
			<button class="google-button login-button" (click)="loginUser(AuthType.GOOGLE)">
				<img class="google-logo" src="https://www.svgrepo.com/show/475656/google-color.svg"
					 alt="Google logo"
					 loading="lazy">
				<span>Login with Google</span>
			</button>
		</div>
	</form>

	<div class="signup-wrapper base-text">
		<span class="base-text not-registered">Not registered? </span>
		<a class="signup" routerLink="/auth/register">Signup</a>
	</div>
</div>