import { Component } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrl: './auth.component.css'
})
export class AuthComponent {
	public isLoginPage?: boolean;

	constructor(
		router: Router
	) {
		router.events
			.subscribe({
				next: (event) => {
					if (event instanceof NavigationEnd) {
						this.isLoginPage = event.url.includes('login');
					}
				}
			})
	}

}
