<div class="card-wrapper">
	<form class="form-wrapper" [formGroup]="registerForm">
		<div class="row">
			<input class="form-item" type="text" placeholder="Nome" formControlName="firstName">
			<input class="form-item" type="text" placeholder="Cognome" formControlName="lastName">
		</div>

		<input class="form-item" type="text" placeholder="Indirizzo e-mail" formControlName="email">

		<div class="password-wrapper">
			<div class="password-input">
				<input class="form-item" [type]="showPassword ? 'text' : 'password'" placeholder="Password"
					   formControlName="password">
				<button class="password show-password" (click)="showPassword = !showPassword">
					<i class="pi pi-eye"></i>
				</button>
			</div>
		</div>

		<div class="password-wrapper">
			<div class="password-input">
				<input class="form-item" [type]="showConfirmPassword ? 'text' : 'password'"
					   placeholder="Conferma password" formControlName="confirmPassword">
				<button class="password show-confirm-password" (click)="showConfirmPassword = !showConfirmPassword">
					<i class="pi pi-eye"></i>
				</button>
			</div>
		</div>

		<div class="register-button-wrapper">
			<button class="form-item register-button" [ngClass]="{'disabled': !canRegister()}"
					(click)="registerUser()" [disabled]="!canRegister()">
				Sign up
			</button>
		</div>
	</form>

	<div class="signup-wrapper base-text">
		<span class="base-text already-registered">Already registered? </span>
		<a class="signin" routerLink="/auth/login">Sign In</a>
	</div>
</div>