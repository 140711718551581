import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, of, Subject } from "rxjs";
import { StorageService } from "./storage.service";
import { StorageKey } from "../models/enumeration/storage-key";
import { Product } from "../shared-components/models/product";
import { CartProduct } from "../cart/models/cart-product";
import { ActionType } from "../models/enumeration/action-type";
import { CouponForm } from "../shared-components/models/coupon-form";
import { CartCosts } from "../models/cart-costs";
import { Shipping } from "../cart/models/shipping";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Cart } from "../cart/models/cart";

@Injectable({
	providedIn: 'root'
})
export class CartService {
	private _cart: Subject<void>;
	private _toggleCart: Subject<void>;
	private shipping?: Shipping;

	constructor(
		private storageService: StorageService,
		private authService: AuthService,
		private httpClient: HttpClient
	) {
		this._cart = new Subject<void>();
		this._toggleCart = new Subject<void>();
	}

	public getDataChanges = (): Observable<void> => {
		return this._cart.asObservable();
	}

	public storeProduct = (product: Product, quantity?: number): Observable<void> => {
		return this.storeCartProduct({ product: product, quantity: quantity ?? 1 });
	}

	public storeCartProduct = (cartProduct: CartProduct): Observable<void> => {
		return this.authService.isUserLogged()
			? this.storeCartProductRemotely(cartProduct)
			: this.storeCartProductLocally(cartProduct);
	}

	public removeProduct = (product: Product): Observable<void> => {
		return this.authService.isUserLogged()
			? this.removeProductRemotely(product)
			: this.removeProductLocally(product);
	}

	public getSavedProducts = (): Observable<CartProduct[]> => {
		return this.authService.isUserLogged()
			? this.getSavedProductsRemotely()
			: this.getSavedProductsLocally();
	}

	public editProductQuantity = (cartProduct: CartProduct, action: ActionType): Observable<void> => {
		const response: Observable<boolean> = this.authService.isUserLogged()
			? this.editProductQuantityRemotely(cartProduct, action)
			: this.editProductQuantityLocally(cartProduct, action);

		return response
			.pipe(
				mergeMap(result => {
					if (result) {
						this.notify();
					}

					return of(void 0);
				})
			);
	}

	public storeCartProductsRemotely = (cartProducts: CartProduct[]): Observable<void> => {
		return this.httpClient.post<void>(`${ environment.apiUrl }/cart/products`, cartProducts)
			.pipe(
				mergeMap(_ => {
					this.notify();
					return of(void 0);
				})
			);
	}

	public getCartCost = (): Observable<CartCosts> => {
		return this.authService.isUserLogged()
			? this.getCartCostRemotely()
			: this.getCartCostLocally();
	}

	public emptyLocalCart = (): Observable<void> => {
		this.storageService.remove(StorageKey.CART_PRODUCTS)
		return of(void 0);
	}

	public getSavedProductsLocally = (): Observable<CartProduct[]> => {
		return of(this.storageService.get(StorageKey.CART_PRODUCTS) ?? []);
	}

	public cartToggled = (): Observable<void> => {
		return this._toggleCart.asObservable();
	}

	public toggleCart = (): void => {
		this._toggleCart.next();
	}

	public setShipping = (shipping: Shipping): void => {
		this.shipping = shipping;
	}

	private _removeProduct = (productId: number): Observable<CartProduct[]> => {
		return this.getSavedProducts()
			.pipe(
				mergeMap(actualProducts => {
					actualProducts.splice(actualProducts.findIndex(cartProduct => cartProduct.product.id === productId), 1);
					return of(actualProducts);
				})
			);
	}

	private notify = (): void => {
		this._cart.next();
	}

	private retrieveExistingProduct = (cartProduct: CartProduct): Observable<CartProduct | undefined> => {
		return this.authService.isUserLogged()
			? this.retrieveExistingProductRemotely(cartProduct)
			: this.retrieveExistingProductLocally(cartProduct.product.id);
	}

	private storeCartProductLocally = (cartProduct: CartProduct): Observable<void> => {
		return this.retrieveExistingProduct(cartProduct)
			.pipe(
				mergeMap(existingProduct => {
					return existingProduct
						? this.addQuantityToProductLocally(existingProduct, cartProduct.quantity)
						: this.getSavedProductsLocally()
							.pipe(
								mergeMap(actualProducts => of([...actualProducts, cartProduct]))
							);
				}),
				mergeMap(products => {
					this.storageService.store(StorageKey.CART_PRODUCTS, products);
					this.notify();
					return of(void 0);
				})
			);
	}

	private storeCartProductRemotely = (cartProduct: CartProduct): Observable<void> => {
		console.log(`Storing ${ cartProduct.product.id } to cart`);
		return of();
	}

	private removeProductLocally = (product: Product): Observable<void> => {
		return this._removeProduct(product.id)
			.pipe(
				mergeMap(updatedProducts => {
					this.storageService.store(StorageKey.CART_PRODUCTS, updatedProducts)
					this.notify();
					return of(void 0);
				})
			);
	}

	private removeProductRemotely = (product: Product): Observable<void> => {
		console.log(`Removing ${ product.id } to cart remotely`);
		return of();
	}

	private getSavedProductsRemotely = (): Observable<CartProduct[]> => {
		return this.httpClient.get<Cart>(`${ environment.apiUrl }/cart`)
			.pipe(
				map(cart => cart.cartProducts)
			);
	}

	private editProductQuantityLocally = (cartProduct: CartProduct, action: ActionType): Observable<boolean> => {
		return this.retrieveExistingProduct(cartProduct)
			.pipe(
				mergeMap(item => {
					if (!item) {
						return of(false);
					}

					return this._removeProduct(cartProduct.product.id)
						.pipe(
							mergeMap(updatedProducts => {
								action === ActionType.INCREASE ? item.quantity++ : item.quantity--;
								updatedProducts.push(item);
								this.storageService.store(StorageKey.CART_PRODUCTS, updatedProducts);

								return of(true);
							})
						);
				})
			);
	}

	private editProductQuantityRemotely = (cartProduct: CartProduct, action: ActionType): Observable<boolean> => {
		console.log('Updating cart product quantity remotely');
		return of(false);
	}

	private getCartCostLocally = (): Observable<CartCosts> => {
		return this.getSavedProducts()
			.pipe(
				mergeMap(savedProducts => {
					const subtotal = savedProducts
						.reduce((previousValue, currentValue) => (previousValue + (currentValue.quantity * (currentValue.product.salePrice ?? currentValue.product.listPrice))), 0);
					return this.doGetCosts(subtotal);
				})
			);
	}

	private getCartCostRemotely = (): Observable<CartCosts> => {
		return this.shipping
			? this.getCompleteCartCostRemotely()
			: this.doGetCostsRemotely()
	}

	private addQuantityToProductLocally = (cartProduct: CartProduct, quantity: number): Observable<CartProduct[]> => {
		return this._removeProduct(cartProduct.product.id)
			.pipe(
				mergeMap(updatedProducts => {
					cartProduct.quantity += quantity;
					return of([...updatedProducts, cartProduct]);
				})
			);
	}

	private addQuantityToProductRemotely = (cartProduct: CartProduct, quantity: number): Observable<CartProduct[]> => {
		console.log(`Adding quantity ${ quantity } to ${ cartProduct.product.id } remotely`);
		return of();
	}

	private retrieveExistingProductLocally = (productId: number): Observable<CartProduct | undefined> => {
		return this.getSavedProducts()
			.pipe(
				mergeMap(cartProducts => {
					return of(cartProducts.find(cartProduct => cartProduct.product.id === productId));
				})
			);
	}

	private retrieveExistingProductRemotely = (cartProduct: CartProduct): Observable<CartProduct> => {
		console.log('Retrieving existing remotely');
		return of();
	}

	private getCompleteCartCostRemotely = (): Observable<CartCosts> => {
		return this.doGetCostsRemotely()
			.pipe(
				mergeMap(cartCosts => this.doGetCosts(cartCosts.subtotal))
			);
	}

	private doGetCostsRemotely = (): Observable<CartCosts> => {
		return this.httpClient.get<CartCosts>(`${ environment.apiUrl }/cart/costs/partial`);
	}

	private doGetCosts = (subtotal: number): Observable<CartCosts> => {
		const coupon = this.storageService.get(StorageKey.COUPON) as CouponForm | undefined;
		const shippingCost: number | undefined = this.shipping && subtotal > this.shipping?.freeAbove ? 0 : this.shipping?.cost;
		const total = subtotal - (coupon?.couponResponse.discountedAmount ?? 0) + (shippingCost ?? 0);
		const shippingCompany = this.shipping?.company ?? '';

		return of({
			couponForm: coupon,
			subtotal: subtotal,
			shippingCost: shippingCost,
			shippingCompany: shippingCompany,
			total: total
		});
	}
}
