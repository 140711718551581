import { Component } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthType } from "../../../models/enumeration/auth-type";
import { getFormControlValue } from "../../../utils/form-utils";
import { Router } from "@angular/router";

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrl: './sign-in.component.css'
})
export class SignInComponent {
	public formGroup: FormGroup;
	public showPassword: boolean;

	constructor(
		private authService: AuthService,
		private router: Router
	) {
		this.formGroup = new FormGroup({
			email: new FormControl<string>('', [Validators.required, Validators.email]),
			password: new FormControl<string>('', Validators.required)
		});
		this.showPassword = false;
	}

	public loginUser = (authType: AuthType): void => {
		if (authType === AuthType.GOOGLE) {
			this.authService.authenticateUserByIdp();
		} else {
			const email = getFormControlValue<string>(this.formGroup, 'email') ?? '';
			const password = getFormControlValue<string>(this.formGroup, 'password') ?? '';
			this.authService.loginUserLocally(email, password)
				.subscribe({
					complete: () => this.router.navigate(['/account'])
				});
		}
	}

	public getForgotPasswordLink = (): string => {
		const email = this.formGroup.get('email')?.value ?? '';
		return email !== ''
			? `/forgot-password?email=${ email }`
			: '/forgot-password';
	}

	protected readonly AuthType = AuthType;
}
