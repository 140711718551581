import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { getFormControlValue } from "../../../utils/form-utils";
import { AuthService } from "../../../services/auth.service";
import { Subscription } from "rxjs";
import { User } from "../../../models/user";
import { StorageService } from "../../../services/storage.service";
import { Router } from "@angular/router";
import { StorageKey } from "../../../models/enumeration/storage-key";

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrl: './sign-up.component.css'
})
export class SignUpComponent implements OnDestroy {
	public registerForm: FormGroup;
	public showPassword: boolean;
	public showConfirmPassword: boolean;

	private registrationSubscription?: Subscription;

	constructor(
		private authService: AuthService,
		private storageService: StorageService,
		private router: Router
	) {
		this.registerForm = new FormGroup({
			firstName: new FormControl<string>('', [Validators.required]),
			lastName: new FormControl<string>('', [Validators.required]),
			email: new FormControl<string>('', [Validators.required, Validators.email]),
			password: new FormControl<string>('', [Validators.required]),
			confirmPassword: new FormControl<string>('', [Validators.required])
		});

		this.showPassword = false;
		this.showConfirmPassword = false;
	}

	ngOnDestroy() {
		this.registrationSubscription?.unsubscribe();
	}

	public registerUser = (): void => {
		const user: User = {
			firstName: getFormControlValue<string>(this.registerForm, 'firstName') ?? '',
			lastName: getFormControlValue<string>(this.registerForm, 'lastName') ?? '',
			email: getFormControlValue<string>(this.registerForm, 'email') ?? '',
			password: getFormControlValue<string>(this.registerForm, 'password') ?? '',
		};

		this.registrationSubscription = this.authService.registerUser(user)
			.subscribe({
				complete: () => this.router.navigate(['/account'])
			});
	}

	public canRegister = (): boolean => {
		return this.registerForm.valid && (getFormControlValue<string>(this.registerForm, 'password') === getFormControlValue<string>(this.registerForm, 'confirmPassword'));
	}
}
