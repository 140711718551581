<div class="container">
	<div class="card">
		<div class="back-button-wrapper">
			<button class="back-button" routerLink="/">
				<i class="pi pi-chevron-left chevron-icon"></i>
			</button>
		</div>

		<h1 class="title base-text">{{ isLoginPage ? 'Accedi' : 'Registrati' }}</h1>

		@if (isLoginPage) {
			<app-sign-in/>
		} @else {
			<app-sign-up/>
		}

	</div>
</div>