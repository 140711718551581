import { Component } from '@angular/core';
import { CartService } from "../../../services/cart.service";
import { mergeMap } from "rxjs";
import { Router } from "@angular/router";

@Component({
	selector: 'app-post-auth',
	templateUrl: './post-auth.component.html',
	styleUrl: './post-auth.component.css'
})
export class PostAuthComponent {

	constructor(cartService: CartService, router: Router) {
		cartService.getSavedProductsLocally()
			.pipe(
				mergeMap(products => cartService.storeCartProductsRemotely(products)),
				mergeMap(_ => cartService.emptyLocalCart())
			)
			.subscribe({
				next: _ => router.navigate(['/account']),
				error: _ => router.navigate(['/account'])
			});
	}

}
