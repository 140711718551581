import { Component } from '@angular/core';
import { AuthService } from "../../../services/auth.service";

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrl: './logout.component.css'
})
export class LogoutComponent {
	constructor(authService: AuthService) {
		authService.logout()
			.subscribe();
	}
}
